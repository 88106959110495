import { graphql } from 'gatsby'
import React from 'react'

import Breadcrumb from '../../components/breadcrumb'
import Heading from '../../components/heading'
import Layout from '../../components/layout'
import Link from '../../components/link'
import List from '../../components/list'
import ListItem from '../../components/list-item'
import SEO from '../../components/seo'


export const frontmatter = {
  body: 'Sitemap',
  slug: '/sitemap/',
  title: 'Sitemap',
}

export const query = graphql`
  query IndexSitemapPageQuery {
    ...SiteInfo
  }
`

export default ({ data }) => {
  const { links, meta } = data.site.siteMetadata
  const { entity } = meta

  const page = 'Sitemap'
  const keywords = [page]
  const trail = [
    {
      name: page,
      slug: links.sitemap.home,
    },
  ]
  const schema = {
    breadcrumb: trail,
    description: page,
    keywords,
    name: page,
    slug: links.sitemap.home,
  }

  return (
    <Layout>
      <SEO keywords={keywords} schema={schema} title={page} />
      <Breadcrumb trail={trail} />

      <Heading>{page}</Heading>

      <List>
        <ListItem>
          <Link href={links.home}>Home Page</Link>
          <List basement={false}>
            <ListItem>
              <Link href={links.privacy}>Privacy Policy Page</Link>
            </ListItem>
            <ListItem>
              <Link href={links.contact.home}>Contact Page</Link>
            </ListItem>
          </List>
        </ListItem>
        <ListItem>
          <Link href={links.sitemap.fragrances}>{entity.plural} Sitemap</Link>
        </ListItem>
        <ListItem>
          <Link href={links.sitemap.accords}>Accords Sitemap</Link>
        </ListItem>
        <ListItem>
          <Link href={links.sitemap.brands}>Brands Sitemap</Link>
        </ListItem>
        <ListItem>
          <Link href={links.sitemap.countries}>Countries Sitemap</Link>
        </ListItem>
        <ListItem>
          <Link href={links.sitemap.families}>Families Sitemap</Link>
        </ListItem>
        <ListItem>
          <Link href={links.sitemap.noses}>Noses Sitemap</Link>
        </ListItem>
        <ListItem>
          <Link href={links.sitemap.notes}>Notes Sitemap</Link>
        </ListItem>
        <ListItem>
          <Link href={links.sitemap.layers}>Pyramid Sitemap</Link>
        </ListItem>
        <ListItem>
          <Link href={links.sitemap.seasons}>Seasons Sitemap</Link>
        </ListItem>
        <ListItem>
          <Link href={links.sitemap.clocks}>Times of Day Sitemap</Link>
        </ListItem>
        <ListItem>
          <Link href={links.sitemap.years}>Years Sitemap</Link>
        </ListItem>
        <ListItem>
          <Link href={links.sitemap.xml}>XML Sitemap</Link>
        </ListItem>
      </List>

      <Breadcrumb trail={trail} />
    </Layout>
  )
}
